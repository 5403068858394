@import 'assets/styles/variables';

.measurement-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 98vh;
  // background-color: $mine-shaft;
  position: relative;

  .measurement-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .video-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 450px;
      height: 450px;

      &::before {
        // todo
        content: "";
        background: #2276f4;
        opacity: 0.25;
        filter: blur(100px);
        width: 555px;
        height: 555px;
        border-radius: 100%;
        position: absolute;
      }

      &::after {
        // todo
        content: "";
        width: 369px;
        height: 369px;
        border-radius: 32%;
        position: absolute;
        border: 4px solid #2276f4;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.75);
      }

      .video-wrapper {
        width: 358px;
        height: 358px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        overflow: hidden;
        border: 4px solid #2276f4;

        .video,
        .canvas {
          height: 100%;
          width: auto;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          
          &.invert {
            transform: translate(-50%, -50%) scaleX(-1);
          }
    
          &.vertical {
            height: auto;
            width: 100%;
          }
        }
        
        .canvas {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) scaleX(-1);
          z-index: 10;
          height: 100%;
        }
      }
    }
  }
}