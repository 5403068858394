@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

$font-family-base: 'Segoe UI', sans-serif;

$font-size-large: 1.5rem;
$font-size-medium: 1.125rem; // 18px
$font-size-normal: 1rem; // 16px
$font-size-small: 0.875rem; // 14px
$font-size-tiny: 0.8125rem;
$font-size-nano: 0.75rem;


$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-line-height-large: 2.25rem;