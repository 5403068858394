@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
@import url(https://fonts.cdnfonts.com/css/segoe-ui-4);
.check-fps-container{display:flex;flex-direction:column;justify-content:center;align-items:center}.check-fps-container .check-fps-message{color:#fff;font-size:1rem;margin:0}.check-fps-container .check-fps-spinner{margin:1rem;position:relative}
.image-quality-container{display:flex;justify-content:center;align-items:flex-start;width:100%;margin-bottom:2rem}.image-quality-container .image-quality{width:20px;height:auto;color:rgba(0,0,0,0);display:inline-block !important}.image-quality-container .image-quality.active{color:#ff0}
.info-container{margin-bottom:1rem}.info-container .info{display:flex;flex-direction:row;justify-content:center;align-items:center}.info-container .info .data{display:flex;flex-direction:row;justify-content:center;flex-wrap:wrap}.info-container .info .data .item{display:flex;flex-direction:column;justify-content:space-between;align-items:center;flex-grow:1;margin:.75rem;font-weight:400;color:#fff;width:100px}.info-container .info .data .item .item-img{position:absolute;left:0;top:0;width:90%;height:90%}.info-container .info .data .item .name{font-size:.75rem}.info-container .info .data .item .value{position:relative;min-height:5rem}.info-container .info .data .item .value .ring-calculating{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:3rem;height:3rem;background:rgba(0,0,0,0)}.info-container .info .data .item .value .ring-calculating:before{content:"";position:absolute;top:-3px;left:-3px;width:100%;height:100%;animation:animateC 2s linear infinite;background:linear-gradient(#012668, #012668) padding-box,linear-gradient(to bottom, #9EA2FF, #9EA2FF, #000) border-box;border-radius:50%;border:3px solid rgba(0,0,0,0)}.info-container .info .data .item .value .ring-calculated{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:3rem;height:3rem;background:rgba(0,0,0,0)}.info-container .info .data .item .value span{display:block;position:absolute;top:calc(50% - 2px);left:50%;width:50%;height:4px;background:rgba(0,0,0,0);transform-origin:left;animation:animate 2s linear infinite}@keyframes animateC{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}@keyframes animate{0%{transform:rotate(45deg)}100%{transform:rotate(405deg)}}
.loading-screen-container{position:absolute;top:0;left:0;bottom:0;right:0;z-index:2;display:flex;flex-direction:column;justify-content:center;align-items:center;color:#fff;font-size:1.125rem;text-align:center;background-color:#0d1429}.loading-screen-container .spinner-wrapper{position:relative;height:6.5rem}.loading-screen-container .message-wrapper{height:6.5rem}
.notification-container{width:100%}.notification-container .notification{font-size:1.25rem;font-weight:bold;text-align:center;margin-bottom:.75rem}.notification-container .notification-type{color:#f1d765;margin-bottom:1rem}.notification-container .notification-type .notification-type-text{font-size:.75rem;text-align:center;display:flex;justify-content:center;align-items:center}.notification-container .notification-type .notification-type-text:before{background-image:url(../../static/media/warning-icon.f7a42bc5.svg);content:"";width:1rem;height:1rem;display:inline-block;background-position:50%;background-repeat:no-repeat;margin-right:.25rem}
.text-message-container{display:flex;justify-content:center;align-items:flex-start;min-height:4.5rem}.text-message-container .text-message{color:#fff;text-align:center;display:flex;flex-direction:column;align-items:center}.text-message-container .text-message.in{overflow:hidden;transition:max-height .25s ease-out,opacity .25s ease-out;opacity:1}.text-message-container .text-message.out{overflow:hidden;transition:max-height .25s ease-out,opacity .25s ease-out;opacity:0}.text-message-container .text-message .welcome-title{font-weight:700;font-size:1.125rem;margin:0;margin-bottom:.5rem}.text-message-container .text-message .welcome-description{font-weight:400;font-size:.875rem;margin:0}
.warning-container{display:flex;flex-direction:column;justify-content:center;align-items:center;min-height:340px;max-width:20rem}.warning-container .warning-image{margin-bottom:1rem}.warning-container .warning-message{color:#fff;font-size:1rem;margin:0}.warning-container.big .warning-image{margin-bottom:1.5rem;width:10.5rem}.warning-container.big .warning-image .warning-message{line-height:1.5rem;margin:0}
.measurement-container{display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;height:98vh;position:relative}.measurement-container .measurement-wrapper{display:flex;flex-direction:column;justify-content:center;align-items:center}.measurement-container .measurement-wrapper .video-container{display:flex;justify-content:center;align-items:center;width:450px;height:450px}.measurement-container .measurement-wrapper .video-container::before{content:"";background:#2276f4;opacity:.25;filter:blur(100px);width:555px;height:555px;border-radius:100%;position:absolute}.measurement-container .measurement-wrapper .video-container::after{content:"";width:369px;height:369px;border-radius:32%;position:absolute;border:4px solid #2276f4;box-shadow:0px 8px 24px rgba(0,0,0,.75)}.measurement-container .measurement-wrapper .video-container .video-wrapper{width:358px;height:358px;position:relative;display:flex;justify-content:center;align-items:center;border-radius:100%;overflow:hidden;border:4px solid #2276f4}.measurement-container .measurement-wrapper .video-container .video-wrapper .video,.measurement-container .measurement-wrapper .video-container .video-wrapper .canvas{height:100%;width:auto;left:50%;top:50%;transform:translate(-50%, -50%);position:absolute}.measurement-container .measurement-wrapper .video-container .video-wrapper .video.invert,.measurement-container .measurement-wrapper .video-container .video-wrapper .canvas.invert{transform:translate(-50%, -50%) scaleX(-1)}.measurement-container .measurement-wrapper .video-container .video-wrapper .video.vertical,.measurement-container .measurement-wrapper .video-container .video-wrapper .canvas.vertical{height:auto;width:100%}.measurement-container .measurement-wrapper .video-container .video-wrapper .canvas{position:absolute;top:0;left:50%;transform:translateX(-50%) scaleX(-1);z-index:10;height:100%}
.not-supported-container{display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;height:98vh;color:#fff;font-size:1.125rem;text-align:center}
.bad-conditions-container{display:flex;flex-direction:column;justify-content:center;align-items:center;height:98vh;color:#fff;font-size:1.125rem;text-align:center;max-width:33.5rem;margin:0 auto}.bad-conditions-container .title-error{color:#f9526b;display:flex;justify-content:center;align-items:center;font-size:.75rem;margin-bottom:1.75rem}.bad-conditions-container .title-error:before{background-image:url(../../static/media/error-icon.0935d066.svg);content:"";width:1rem;height:1rem;display:inline-block;background-position:50%;background-repeat:no-repeat;margin-right:.25rem}.bad-conditions-container .message-container{margin-bottom:2.85rem}.bad-conditions-container .message-container .title{font-size:1.25rem;font-weight:bold;margin-bottom:.5rem}.bad-conditions-container .message-container .content{font-size:.875rem}
.results-container{display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;height:98vh;color:#fff;font-size:1.125rem;text-align:center}.results-container .msg-block{max-width:536px}.results-container .msg-block .title{font-weight:700;font-size:18px}.results-container .msg-block .description{font-weight:400;font-size:14px}.results-container .results{align-items:center;display:flex;flex-direction:column;max-width:400px;justify-content:center}.results-container .results .item{width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center;flex-grow:1;margin-bottom:1rem;padding-bottom:1rem;font-weight:400;font-size:1rem;color:#fff;border-bottom:1px solid rgba(255,255,255,.4)}.results-container .results .item .title{display:flex;flex-direction:row;justify-content:flex-start;align-items:center;min-width:12.5rem}.results-container .results .item .title .icon{font-size:.75rem;margin-right:1.3rem}.results-container .results .item .value{text-align:right}.results-container .results .item .value .sign{margin-left:.25rem}.results-container .results .notification{display:flex;flex-direction:row;color:#fff;text-align:left;font-size:1rem}.results-container .results .notification img{margin-right:.5rem}.results-container .input-email{width:20rem;box-sizing:border-box;margin:.5rem;padding:1.3rem 1.625rem;border-radius:10px;border:none}.bp-stats{font-size:12px !important}
body{background:linear-gradient(122deg, #000 5%, #000 87%) !important}.ui-provider{background:linear-gradient(122deg, #000 5%, #000 87%) !important}svg{display:none}.start-examn{padding:13px 71px 13px 72px;background:linear-gradient(109deg, #00c1ff 18%, #2276f4 135%);border-radius:5px;border:0;font-size:24px;font-family:"Helvetica Neue";font-weight:400}.start-examn:hover{background:linear-gradient(109deg, #00c1ff 135%, #2276f4 18%);cursor:pointer}.home-container{display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;color:#fff;font-size:1.125rem;text-align:center}.page-title{font-size:24px}.footer-menu{list-style:none;margin:0;padding:0;margin-top:100px}.footer-menu li{margin:0 0 20px 0;padding:0}.footer-menu li a{color:#fff;font-size:14px;font-family:"Helvetica Neue";font-style:normal}@media screen and (max-width: 600px){.stats-img{width:100% !important}}
body{background:#0d1429 !important}.ui-provider{background:#0d1429 !important}svg{display:none}.start-examn{padding:13px 71px 13px 72px;background:linear-gradient(109deg, #00c1ff 18%, #2276f4 135%);border-radius:5px;border:0;font-size:24px;font-family:"Helvetica Neue"}.start-examn:hover{background:linear-gradient(109deg, #00c1ff 135%, #2276f4 18%);cursor:pointer}.disclaimers-container{display:flex;flex-direction:column;align-items:center;height:89vh;max-width:500px;color:#fff;font-size:1.125rem;text-align:center;margin:0 auto}.page-title{font-size:24px}.footer-menu{list-style:none;margin:0;padding:0;margin-top:100px}.footer-menu li{margin:0 0 20px 0;padding:0}.footer-menu li a{color:#fff;font-size:14px;font-family:"Helvetica Neue";font-style:normal}
body{background:#0d1429 !important}.ui-provider{background:#0d1429 !important}svg{display:none}.start-examn{padding:13px 71px 13px 72px;background:linear-gradient(109deg, #00c1ff 18%, #2276f4 135%);border-radius:5px;border:0;font-size:24px;font-family:"Helvetica Neue"}.start-examn:hover{background:linear-gradient(109deg, #00c1ff 135%, #2276f4 18%);cursor:pointer}.before-test-container{display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;height:89vh;max-width:500px;color:#fff;font-size:1.125rem;text-align:center;margin:0 auto}.page-title{font-size:24px}.footer-menu{list-style:none;margin:0;padding:0;margin-top:100px}.footer-menu li{margin:0 0 20px 0;padding:0}.footer-menu li a{color:#fff;font-size:14px;font-family:"Helvetica Neue";font-style:normal}@media screen and (max-width: 600px){.mindfulness-img{width:100% !important}.text-img{width:100% !important}}

*{box-sizing:border-box}body{width:100%;margin:5em;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
