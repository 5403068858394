// colors
$scooter: #29BACD;
$black: #000000;
$jaffa: #E68139;
$del-rio: #B19797;

$white: #FFFFFF;
$tundora: #404040;
$crimson: #E42313;
$cerulean: #13A5E4;
$regent-gray: #878E99;

$electric-violet: #4900FF;
$royal-blue: #4180E4;
$midnight-blue: #002768;

$scampi: #6264A7;
$mine-shaft: #242424;
$melrose: #9EA2FF;

$carnation: #F9526B;
$cream-can: #F1D765;
