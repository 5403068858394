@import 'assets/styles/variables';

body {
    background: #0D1429 !important
    // background: linear-gradient(122deg, #1a2239 5%, #040a1d 87%) !important

}
.ui-provider {
    background: #0D1429 !important
}

svg {
    display: none;
}

.start-examn {
    padding: 13px 71px 13px 72px;
    background: linear-gradient(109deg, #00c1ff 18%, #2276f4 135%);
    border-radius: 5px;
    border: 0;
    font-size: 24px;
    font-family: 'Helvetica Neue';
     &:hover {
        background: linear-gradient(109deg, #00c1ff 135%, #2276f4 18%);
        cursor: pointer;
     }
}

.disclaimers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 89vh;
    max-width: 500px;
    color: $white;
    font-size: $font-size-medium;
    text-align: center;
    margin: 0 auto;
}

.page-title {
    font-size: 24px;
}

.footer-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 100px;
    li {
        margin: 0 0 20px 0;
        padding: 0;
        a {
            color: white;
            font-size: 14px;
            font-family: 'Helvetica Neue';
            font-style: normal;
        }
    }
}