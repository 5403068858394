@import 'assets/styles/variables';

.warning-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 340px;
  max-width: 20rem;
  
  .warning-image {
    margin-bottom: 1rem;
  }

  .warning-message {
    color: $white;
    font-size: 1rem;
    margin: 0;
  }

  &.big {
    .warning-image {
      margin-bottom: 1.5rem;
      width: 10.5rem;

      .warning-message {
        line-height: 1.5rem;
        margin: 0;
      }
    }
  }
}