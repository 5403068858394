@import 'assets/styles/variables';

.not-supported-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 98vh;
  color: $white;
  font-size: $font-size-medium;
  text-align: center;
}
